<template>
	<v-container class="student__courses">
		<v-row>
			<v-col>
				<AdminDefaultHeader
					>{{ userName }}'s Certificates</AdminDefaultHeader
				>
			</v-col>
		</v-row>
		<v-row class="d-flex flex-row justify-space-between">
			<v-col cols="3" class=""></v-col>
			<v-col cols="3" class="d-flex flex-row justify-content-end">
				<v-text-field
					v-model="searchingText"
					class="search-course"
					placeholder="Start typing to Search"
					filled
					:loading="isLoading"
				></v-text-field
			></v-col>
		</v-row>
		<v-row>
			<v-col>
				<StudentCourseList
					v-for="certificate in displayedCertificates.slice(0, limit)"
					:key="certificate.id"
					:courseId="certificate.id"
					:name="certificate.course && certificate.course.title"
					:dateStart="certificate.created_at"
					:dateCompleted="certificate.completed_at"
					:certificateLayout="true"
					:displayPopup="displayPopup"
					:userName="userName"
					@closePopup="closePopup"
					@downloadCetificate="seeCertificates"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column align-center">
				<v-btn
					v-if="certificates.length > limit"
					class="admin-primary-button secondary-contrast-background"
					@click="loadMoreCourses"
					>Load More</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { get } from "@/util/requests/get";
import StudentCourseList from "../../components/StudentCourseList.vue";
import { mapGetters, mapState } from "vuex";

export default {
	name: "StudentCertificates",
	components: {
		StudentCourseList
	},
	props: {},
	computed: {
		...mapGetters(["userId", "isLoading"]),
		...mapState({
			userName: state => state.user.firstName
		}),
		displayedCertificates() {
			return this.certificates?.filter(el =>
				String(el.course?.title).includes(this.searchingText)
			);
		}
	},
	data() {
		return {
			certificates: [],
			limit: 2,
			searchingText: "",
			displayPopup: false
		};
	},
	methods: {
		goTo(name, query) {
			this.$router.push({ name: name, query: query });
		},
		async getCertificates(query = "") {
			const {
				data: { data }
			} = await get(`/reports?${query}`);
			return data;
		},
		seeCertificates() {
			this.displayPopup = true;
		},
		loadMoreCourses() {
			if (!!this.certificates === false) return;
			this.limit = this.certificates.length;
		},
		closePopup() {
			this.displayPopup = false;
		}
	},
	async created() {
		this.certificates = await this.getCertificates(
			`include=user,course&filters=user_id&filter_value=${this.userId}`
		);
	}
};
</script>

<style></style>
